import axios, { AxiosRequestConfig } from 'axios'

import { IReqData, IRes } from './interface'
import './__mock__'

/** 获取缺货库存列表 */
export const apiGetRecords = async (
  tabType: '1' | '2',
  params: IReqData,
  signal?: AxiosRequestConfig['signal'],
): Promise<IRes> => {
  const { sku, businessNo, stashName, merchantUserIds } = params
  return axios
    .get(tabType === '1' ? '/linkhub_oms/stockFlow/list/physical' : '/linkhub_oms/stockFlow/list/preemption', {
      params: {
        ...params,
        sku: sku?.trim() || undefined,
        businessNo: businessNo?.trim() || undefined,
        stashName: stashName?.trim() || undefined,
        merchantUserIds: merchantUserIds?.length ? merchantUserIds : undefined,
      },
      signal,
    })
    .then(res => ({
      ...res.$data,
      records: res.$data?.records || [],
    }))
}
