import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { Button, Input, Space } from 'antd'

import { CusImage } from '@/components/CusImage'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { MerchantSelect } from '../../components/MerchantSelect'
import { apiGetRecords } from './apis'
import { IReqData, IRes, TCol } from './interface'
import styles from './styles.module.less'

export const OutOfStock = ({ isEmployee = false }: { isEmployee?: boolean }) => {
  const { t } = useTranslation()
  const [sku, setSku] = useState<IReqData['sku']>()
  const [spu, setSpu] = useState<IReqData['spu']>()
  const [productEnTitle, setProductEnTitle] = useState<IReqData['productEnTitle']>()
  const [merchantUserIds, setMerchantUserIds] = useState<IReqData['merchantUserIds']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = {
    sku,
    spu,
    productEnTitle,
    merchantUserIds,
    page,
    size,
  }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const columns = useMemo<TCol[]>(() => {
    return [
      {
        title: t('Warehouse.chan-pin-id'),
        render: (_v, { skuId: v }) => v,
      },
      {
        title: t('Warehouse.chan-pin-tu-pian'),
        render: (_v, { imgUrl: v }) => <CusImage height={50} width={50} src={v || CusImage.defaultImg} />,
      },
      {
        title: 'SKU',
        render: (_v, { sku: v }) => v,
      },
      {
        title: 'SPU',
        render: (_v, { spu: v }) => v,
      },
      {
        title: t('Warehouse.ying-wen-pin-ming'),
        render: (_v, { productEnTitle: v }) => v,
      },
      {
        title: t('2-common.shang-jia-ming-cheng'),
        hidden: !isEmployee,
        render: (_v, { merchantUserName: v }) => v,
      },
      {
        title: t('Warehouse.cang-ku-ming-cheng'),
        render: (_v, { stashName: v }) => v,
      },
      {
        title: t('Warehouse.que-huo-shu-liang'),
        render: (_v, { outAmount: v }) => v,
      },
    ]
  }, [isEmployee, t])

  const handlePressEnter = () => {
    setPage(1)
    getList({ ...reqData, page: 1 })
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <Space>
          <Input
            placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
            allowClear
            addonBefore="SKU"
            value={sku}
            onChange={e => setSku(e.target.value)}
            onPressEnter={handlePressEnter}
          />
          <Input
            placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
            allowClear
            addonBefore="SPU"
            value={spu}
            onChange={e => setSpu(e.target.value)}
            onPressEnter={handlePressEnter}
          />
          <Space>
            <Input
              placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
              allowClear
              addonBefore={t('Warehouse.ying-wen-pin-ming')}
              value={productEnTitle}
              onChange={e => setProductEnTitle(e.target.value)}
              onPressEnter={handlePressEnter}
            />
            {isEmployee && (
              <MerchantSelect
                style={{ width: 160 }}
                value={merchantUserIds?.[0]}
                onChange={val => {
                  const merchantUserIds = val ? [val] : undefined
                  setMerchantUserIds(merchantUserIds)
                  setPage(1)
                  getList({ ...reqData, merchantUserIds, page: 1 })
                }}
              />
            )}
            <Space size={4} style={{ marginLeft: 8 }}>
              <Button
                type="primary"
                onClick={() => {
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                }}
              >
                {t('2-common.sou-suo')}
              </Button>
              <Button
                onClick={() => {
                  const vo = undefined
                  setSku(vo)
                  setSpu(vo)
                  setProductEnTitle(vo)
                  setMerchantUserIds(vo)
                  setPage(1)
                  getList({
                    ...reqData,
                    sku: vo,
                    spu: vo,
                    productEnTitle: vo,
                    merchantUserIds: vo,
                    page: 1,
                  })
                }}
              >
                {t('2-common.zhong-zhi')}
              </Button>
            </Space>
          </Space>
        </Space>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="skuId"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>
    </div>
  )
}
