import axios, { AxiosRequestConfig } from 'axios'

import { IReqData, IRes } from './interface'
import './__mock__'

/** 获取缺货库存列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  const { sku, spu, productEnTitle, merchantUserIds } = params
  return axios
    .get('/linkhub_oms/outstock/list', {
      params: {
        ...params,
        sku: sku?.trim() || undefined,
        spu: spu?.trim() || undefined,
        productEnTitle: productEnTitle?.trim() || undefined,
        merchantUserIds: merchantUserIds?.length ? merchantUserIds : undefined,
      },
      signal,
    })
    .then(res => ({
      ...res.$data,
      records: res.$data?.records || [],
    }))
}
